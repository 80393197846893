<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            Proyectos Cooperación Norte-Sur próximos a vencer
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          ref="myTable"
          class="elevation-0"
          :headers="headers"
          :items="proyectos"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          noResultsText="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    filled
                    autocomplete="off"
                    dense
                    v-model="filtro"
                    label="Código / Nombre del proyecto"
                    maxlength="100"
                  >
                    <v-icon slot="append" color="black">
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <export-excel
                    class="white--text mb-2 float-right v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default light-blue-502 btn"
                    color="light-blue-502"
                    dark
                    :disabled="proyectos.length <= 0 || tableLoading"
                    :data="proyectos"
                    :fields="excelHeaders"
                    type="xls"
                    :title="`Proyectos de Cooperación Norte Sur`"
                    :footer="`${proyectos.length} proyectos encontrados`"
                    worksheet="Proyectos CNS"
                    name="ProyectosCNSProximosVencer.xls"
                  >
                    Exportar a excel
                    <v-icon class="ml-2" color="white"
                      >mdi-file-excel-box</v-icon
                    >
                  </export-excel>

                  <export-excel
                    class="white--text mb-2 float-right v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default light-blue-502 mr-2 btn"
                    color="light-blue-502"
                    dark
                    :disabled="proyectos.length <= 0 || tableLoading"
                    :data="proyectos"
                    type="csv"
                    :fields="excelHeaders"
                    :title="`Proyectos de Cooperación Norte Sur`"
                    :footer="`${proyectos.length} proyectos encontrados`"
                    worksheet="Proyectos CNS"
                    name="ProyectosCNSProximosVencer.xls"
                  >
                    Exportar a csv
                    <v-icon class="ml-2" color="white"
                      >mdi-file-delimited</v-icon
                    >
                  </export-excel>
                  <!-- <v-btn @click="createPDF" color="primary">Download as PDF</v-btn> -->
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.codigo }}</td>
              <td>{{ item.refSigeaci }}</td>
              <td>{{ item.fechaSolicitudOpinionTecnica }}</td>
              <td>{{ item.nombreProyecto }}</td>
              <td>
                {{ item.fechaInicioPlazos | formatDate }}<br />
                <span>{{ item.fechaFinalizacionPlazos | formatDate }}</span>

                <!-- <v-btn
                  v-if="
                    item.estadoProyectoId != 4 &&
                      new Date(currentDate) >
                        new Date(item.fechaFinalizacionPlazos)
                  "
                  color="deep-orange lighten-2"
                  class="ml-1"
                  @click="
                    mostrarDialogFechas(
                      item.codigo,
                      item.fechaFinalizacionPlazos
                    )
                  "
                  text
                  fab
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-btn> -->
              </td>

              <td>
                {{ item.nombreInstitucion }}<br />
                <span>{{ item.nombreUnidadEjecutora }}</span>
              </td>
              <td>
                <v-chip
                  label
                  class="ma-2"
                  :color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-4'
                      : item.estadoProyectoId === 6
                      ? 'cyan lighten-4'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadoProyectoId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadoProyectoId === 2
                      ? 'blue lighten-1'
                      : item.estadoProyectoId === 6
                      ? 'cyan darken-1'
                      : item.estadoProyectoId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadoProyecto }}
                </v-chip>
              </td>
            </tr>
          
            </template>
          </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OBTENER_PROYECTOS_CNS_USUARIO_INSTITUCION, OBTENER_PROYECTOS_CNS } from "@/core/services/store/proyectoscns/proyectocns.module";
//import * as jsPDF from "jspdf";
//import 'jspdf-autotable'

moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
export default {
  name: "ReporteProyectosCnsVencimiento",
  components: {
    DivisorColor,
  },
  data() {
    return {
      proyectos: [],
      estadosProyecto: [
        { text: "Todos", value: null },
        { value: 1, text: "Registro" },
        { value: 2, text: "Seguimiento" },
        { value: 6, text: "Finalizado" },
        { value: 7, text: "Suspendido" }
      ],
      estadoProyecto: {},
      tableLoading: false,
      filtro: "",
      currentDate: "",
      dateNow: moment(),
      excelHeaders: {
        "Codigo SICOOPERA": "codigo",
        "Código SIGEACI": "refSigeaci",
        "Fecha de solicitud opinión Técnica": "fechaSolicitudOpinionTecnica",
        "Nombre del Proyecto": "nombreProyecto",
        "Institución": "nombreInstitucion",
        "Unidad Ejecutora": "nombreUnidadEjecutora",
        "Fecha de Finalización": "fechaFinalizacionPlazos",
        /*'Tipo de Fuente Cooperante' : {
                field: 'tipoFuenteCooperante',
                callback: (value) => {
                    return `Landline Phone - ${value}`;
                } ,*/
        "Estado": "estadoProyecto"

        /*  'Telephone 2' : {
                field: 'phone.landline',
                callback: (value) => {
                    return `Landline Phone - ${value}`;
                } 
            },*/
      },

    }
  },
  methods: {
    //Obtener proyectos
    async obtenerProyectos() {
      //let token = JwtService.getToken();
      this.proyectos = [];
      this.tableLoading = true;

      this.$store
        .dispatch(OBTENER_PROYECTOS_CNS_USUARIO_INSTITUCION, { estadoId: 66 })
        .then(res => {
          if (res.status === 200) {
            this.proyectos = res.data;
          }
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    //Obtener los proyectos registrados
    async obtenerProyectosAdmin() {
      this.tableLoading = true;

      this.proyectos = [];
      await this.$store
        .dispatch(OBTENER_PROYECTOS_CNS, { estadoProyectoId: 66 })
        .then(res => {
          if (res.status == 200) {
            this.proyectos = res.data;
          }

          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },

    /*createPDF () {
      var source =  this.$refs["myTable"];
      let rows = [];
      let columnHeader = ['Codigo SICOOPERA', 'Codigo SIGEACI', 'Fecha de solicitud opinión Técnica', 'Nombre del Proyecto', 'Institución', 'Unidad Ejecutora', 'Fecha de Finalización', 'Estado del Proyecto'];
      let pdfName = 'Schedule';
      source.items.forEach(element => {
          var temp = [
              element.StartTime,
              element.TERMDESC,
              element.M1 || '' + element.M4 || '' + element.M2 || '' + element.M3 || '',
              element.T1 || '' + element.T4 || '' + element.T2 || '' + element.T3 || '',
              element.W1 || '' + element.W4 || '' + element.W2 || '' + element.W3 || '',
              element.R1 || '' + element.R4 || '' + element.R2 || '' + element.R3 || '',
              element.F1 || '' + element.F4 || '' + element.F2 || '' + element.F3 || '',
              element.S1 || '' + element.S4 || '' + element.S2 || '' + element.S3 || '',
              //element.U1 || '' + element.U4 || '' + element.U2 || '' + element.U3 || '',
          ];
          rows.push(temp);
      });
      var doc = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: "letter"
      });
      doc.setFontSize(16).text("Proyectos de Cooperación Norte-Sur", 0.5, 1.0);
      // @ts-ignore
      doc.autoTable(columnHeader, rows, { startY: 10 });
      
      doc.setLineWidth(0.01).line(0.5, 1.1, 8.0, 1.1);
      doc.save(pdfName + '.pdf');
    }*/
  },
  created() {
    this.currentDate = this.dateNow.format("MM/DD/YYYY");
    if (
      this.currentRoles.includes("SCO$ADMINISTRADOR") ||
      this.currentRoles.includes("SCO$SUBADMINISTRADOR")
    ) {
      this.obtenerProyectosAdmin();
    } else {
      this.obtenerProyectos();
    }
  },
  mounted() {
    /* let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/2.3.2/jspdf.plugin.autotable.min.js')
      document.body.appendChild(recaptchaScript) */

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard-institucion" },
      { title: "Proyectos CNS" },
      { title: "Próximos a vencer" }
    ]);
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }), //, currentRoles :"currentRoles"
    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo"
        },
        {
          text: "Código SIGEACI",
          align: "start",
          sortable: false,
          value: "refSigeaci"
        },
        {
          text: "Fecha opinión técnica",
          align: "start",
          sortable: true,
          value: "fechaOpinionTecnica"
        },
        {
          text: "Nombre Proyecto",
          align: "start",
          sortable: true,
          value: "nombreProyecto"
        },
        {
          text: "Fecha de finalización",
          align: "start",
          sortable: true,
          value: "fechaFinalizacionPlazos"
        },

        {
          text: "Unidad Ejecutora",
          align: "start",
          sortable: true,
          value: "nombreUnidadEjecutora"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estadoProyectoId",
          filter: this.filtroEstado
        }/* ,
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        } */
      ];
    }
  }
};
</script>
